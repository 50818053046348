<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-autocomplete
      v-model="value"
      :class="`dropdown-multiselect${classes}`"
      :disabled="disabled"
      :items="options"
      item-text="text"
      item-value="id"
      return-object
      autocomplete="on"
      :no-data-text="lang('ChecklistBuilder.templates.dropdown.option_no_data')"
      chips
      multiple
    >
      <template
        v-slot:selection="data"
      >
        <v-chip
          :input-value="data.selected"
          close
          class="chip--select-multi"
          @click:close="removeItem(data.item)"
        >
          <v-avatar
            v-if="validColor(data.item.color)"
            :color="data.item.color"
            left
          />
          {{ data.item.text }}
        </v-chip>
      </template>
      <template
        v-slot:item="data"
      >
        <v-list-item-avatar
          v-if="validColor(data.item.color)"
          :color="data.item.color"
          size="8"
          class="mr-2 elevation-1"
        />
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeMultiselect',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    options() {
      const optionsOriginal = this.cell.settings.dropdown_builder || []
      const optionsSorting = parseInt(this.cell.settings.dropdown_sorting || 0) || 0
      const options = []
      if (!Array.isArray(optionsOriginal)) {
        return options
      }
      for (let i = 0, option; (option = optionsOriginal[i]); i++) {
        if (typeof option === 'string') {
          option = {
            value: option,
            color: ''
          }
        }
        option.id = i
        option.text = this.optionLabel(option)
        options.push(option)
      }
      if (optionsSorting === 1) {
        options.sort(function(a, b) {
          if (a.text > b.text) {
            return 1;
          }
          if (a.text < b.text) {
            return -1;
          }
          return 0;
        })
      }
      if (optionsSorting === 2) {
        options.sort(function(a, b) {
          if (a.text > b.text) {
            return -1;
          }
          if (a.text < b.text) {
            return 1;
          }
          return 0;
        })
      }
      return options
    },
    value: {
      get() {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = builder.getCellValue(index, this.cell, this.clone)
        try {
          if (typeof value === 'string') {
            value = JSON.parse(value)
          }
        } catch (ex) {
          value = []
        }
        const result = []
        if (Array.isArray(value)) {
          for (let i = 0, entry; (entry = value[i]); i++) {
            if (entry.hasOwnProperty('id')) {
              result.push({
                id: parseInt(entry.id) || 0,
                label: entry.label || ''
              })
            }
          }
        }
        return result
      },
      set(event) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        const value = []
        if (Array.isArray(event)) {
          for (let i = 0, entry; (entry = event[i]); i++) {
            if (entry.hasOwnProperty('id')) {
              value.push({
                id: entry.id,
                label: entry.text || entry.label || ''
              })
            }
          }
        }
        const previousValue = this.value
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    optionLabel(option) {
      const builder = this.data.ChecklistBuilder
      return builder.getLocalization(option, 'value') || ''
    },
    removeItem(item) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = []
      if (Array.isArray(this.value)) {
        for (let i = 0, entry; (entry = this.value[i]); i++) {
          if (entry.hasOwnProperty('id') && entry.id !== item.id) {
            value.push({
              id: entry.id,
              label: entry.label || ''
            })
          }
        }
      }
      const previousValue = this.value
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    },
    validColor(color) {
      if (!color) {
        return false
      }
      if (/transparent/i.test(color)) {
        return false
      }
      if (/rgba\(([0-9]+, ){3}0\)/i.test(color)) {
        return false
      }
      return true
    }
  }
}
</script>
